<template>
  <div>
    <div>
      <div class="field is-grouped">
        <div v-if="active !== null" class="control">
          <button
            v-tippy="{ placement: 'right' }"
            class="button"
            :title="[active ? 'Disable' : 'Enable']"
            @click="update('toggleStatus')"
          >
            <span class="icon">
              <i
                :class="[
                  active
                    ? 'fas fa-ban has-text-danger'
                    : 'fas fa-check-circle has-text-success'
                ]"
              ></i>
            </span>
          </button>
        </div>
        <div v-if="orgId || branchId" class="control">
          <button
            v-tippy="{ placement: 'right' }"
            class="button"
            title="Remove"
            @click="update('remove')"
          >
            <span class="icon">
              <i class="fas fa-minus-circle has-text-warning"></i>
            </span>
          </button>
        </div>
        <div v-if="hasStockviewAccess" class="control">
          <button
            v-tippy="{ placement: 'right' }"
            class="button"
            :title="
              stockviewEmail === 1
                ? 'Disable stockview daily report'
                : 'Enable stockview daily report'
            "
            @click="toggleStockviewEmail"
          >
            <span class="icon">
              <i
                v-bind:class="[
                  'fas',
                  'fa-envelope',
                  {
                    'has-text-info': stockviewEmail,
                    'has-text-grey-lighter': !stockviewEmail
                  }
                ]"
              ></i>
            </span>
          </button>
        </div>
        <div v-if="multi" class="control">
          <button
            v-tippy="{ placement: 'right' }"
            class="button"
            :disabled="multi.error"
            title="Download file"
            @click="update('download')"
          >
            <span class="icon">
              <i class="fas fa-download has-text-success"></i>
            </span>
          </button>
        </div>
        <div v-if="userId" class="control">
          <button
            v-tippy="{ placement: 'right' }"
            class="button"
            title="Reset password"
            @click="update('password')"
          >
            <span class="icon">
              <i class="fas fa-key has-text-success"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animated faster zoomIn"
      leave-active-class="animated faster zoomOut"
    >
      <modal-confirm v-if="modal" @confirm="update" @close="cancel" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TableActionButtons',
  components: {
    'modal-confirm': () => import('./ModalConfirm')
  },

  props: {
    active: {
      type: Boolean,
      required: false,
      default: () => null
    },
    stockviewEmail: {
      type: Number,
      required: true
    },
    branchId: {
      type: Number,
      required: false,
      default: () => null
    },
    orgId: {
      type: Number,
      required: false,
      default: () => null
    },
    userId: {
      type: Number,
      required: false,
      default: () => null
    },
    multi: {
      type: Object,
      required: false,
      default: () => null
    },
    hasStockviewAccess: {
      type: [Number, Boolean],
      required: false,
      default: () => false
    }
  },
  data: () => ({
    modal: false,
    action: null
  }),

  methods: {
    update(action) {
      if (action) {
        this.action = action
      }

      if (this.modal || this.action === 'download') {
        this.$emit(this.action)
      }

      if (action !== 'download') {
        this.modal = !this.modal
      }
    },
    cancel() {
      this.action = null
      this.modal = !this.modal
    },
    toggleStockviewEmail() {
      this.$emit(
        'toggleStockviewEmail',
        this.userId,
        this.stockviewEmail === 1 ? 0 : 1,
      )
    }
  }
}
</script>
