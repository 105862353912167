var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"field is-grouped"},[(_vm.active !== null)?_c('div',{staticClass:"control"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"button",attrs:{"title":[_vm.active ? 'Disable' : 'Enable']},on:{"click":function($event){return _vm.update('toggleStatus')}}},[_c('span',{staticClass:"icon"},[_c('i',{class:[
                _vm.active
                  ? 'fas fa-ban has-text-danger'
                  : 'fas fa-check-circle has-text-success'
              ]})])])]):_vm._e(),(_vm.orgId || _vm.branchId)?_c('div',{staticClass:"control"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"button",attrs:{"title":"Remove"},on:{"click":function($event){return _vm.update('remove')}}},[_vm._m(0)])]):_vm._e(),(_vm.hasStockviewAccess)?_c('div',{staticClass:"control"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"button",attrs:{"title":_vm.stockviewEmail === 1
              ? 'Disable stockview daily report'
              : 'Enable stockview daily report'},on:{"click":_vm.toggleStockviewEmail}},[_c('span',{staticClass:"icon"},[_c('i',{class:[
                'fas',
                'fa-envelope',
                {
                  'has-text-info': _vm.stockviewEmail,
                  'has-text-grey-lighter': !_vm.stockviewEmail
                }
              ]})])])]):_vm._e(),(_vm.multi)?_c('div',{staticClass:"control"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"button",attrs:{"disabled":_vm.multi.error,"title":"Download file"},on:{"click":function($event){return _vm.update('download')}}},[_vm._m(1)])]):_vm._e(),(_vm.userId)?_c('div',{staticClass:"control"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],staticClass:"button",attrs:{"title":"Reset password"},on:{"click":function($event){return _vm.update('password')}}},[_vm._m(2)])]):_vm._e()])]),_c('transition',{attrs:{"enter-active-class":"animated faster zoomIn","leave-active-class":"animated faster zoomOut"}},[(_vm.modal)?_c('modal-confirm',{on:{"confirm":_vm.update,"close":_vm.cancel}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-minus-circle has-text-warning"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-download has-text-success"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-key has-text-success"})])
}]

export { render, staticRenderFns }